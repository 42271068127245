body{
    background-color: #08070A !important;
}
@font-face {
    font-family: Supreme-Bold;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-Bold.otf");
  }
  @font-face {
    font-family: Supreme-BoldItalic;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-BoldItalic.otf");
  }
  @font-face {
    font-family: Supreme-Extrabold;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-Extrabold.otf");
  }
  @font-face {
    font-family: Supreme-ExtraboldItalic;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-ExtraboldItalic.otf");
  }
  @font-face {
    font-family: Supreme-Extralight;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-Extralight.otf");
  }
  @font-face {
    font-family: Supreme-ExtralightItalic;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-ExtralightItalic.otf");
  }
  @font-face {
    font-family: Supreme-Italic;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-Italic.otf");
  }
  @font-face {
    font-family: Supreme-Light;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-Light.otf");
  }
  @font-face {
    font-family: Supreme-LightItalic;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-LightItalic.otf");
  }
  @font-face {
    font-family: Supreme-Medium;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-Medium.otf");
  }
  @font-face {
    font-family: Supreme-MediumItalic;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-MediumItalic.otf");
  }
  @font-face {
    font-family: Supreme-Regular;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-Regular.otf");
  }
  @font-face {
    font-family: Supreme-Thin;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-Thin.otf");
  }
  @font-face {
    font-family: Supreme-ThinItalic;
    src: url("/src/assets/font/Supreme_Complete/Fonts/OTF/Supreme-ThinItalic.otf");
  }
  