@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

#bg-nav {
  background: transparent linear-gradient(0deg, #08070A00 0%, #08070A 100%) 0% 0% no-repeat padding-box;
}

#hero {
  background: url(../../assets/webpimages/Main-banner.webp);
  background-position: center;
  background-size: cover;
}

@media only screen and (min-width: 768px) {
  #hero {
    position: relative;
    top: -71px;
  }
}

#characters {
  background: url(../../assets/webpimages/bnr-characters.webp);
  background-position: center;
  background-size: cover;
}

.bg-preview {
  background: url(../../assets/webpimages/banner.webp);
  background-position: center;
  background-size: cover;
}

.bg-author {
  background: url(../../assets/webpimages/author\ BG@2x.webp);
  background-position: center;
  background-size: cover;
}

.sampleBtn {
  color: #08070a;
  width: 234px;
  height: 60px;
  box-shadow: 0px 0px 25px #000000c9;
  border-radius: 30px;
  background: transparent linear-gradient(0deg, #e48944 0%, #ecff34 100%) 0% 0% no-repeat padding-box;
  border: none;
}

.section-title {
  margin-bottom: 60px;
}

.section-title .line {
  width: 60px;
  height: 4px;
  /* background-color: var(--c-brand); */
  margin: 16px auto 24px auto;
}

.section-title p {
  color: white;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
  height: auto;
}

.section-title-text {
  font-family: "Russo One", Regular;
  color: white;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.footerBg {
  background: #08070A 0% 0% no-repeat padding-box;
  border-radius: 24px;
}

.buy {
  color: #08070a;
  width: 234px;
  height: 60px;
  box-shadow: 0px 0px 25px #000000c9;
  border-radius: 30px;
  background: transparent linear-gradient(0deg, #e48944 0%, #ecff34 100%) 0% 0% no-repeat padding-box;
  border: none;
}

.nav-link {
  font-family: Supreme-ExtraboldItalic;
  color: #ffffff !important;
  font-size: 18px !important;
  letter-spacing: 0px;
  line-height: 36px;
  opacity: 1;
  text-transform: uppercase;
  transition: .2s ease-in-out !important;
  border-bottom: 2px solid transparent !important;
}

.nav-link:hover {
  border-bottom: 2px solid #ECFB35 !important;
  opacity: 100%;
}

.buybtn {
  background: transparent linear-gradient(0deg, #e48944 0%, #ecff34 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #000000c9;
  border-radius: 30px;
  opacity: 1;
  font-size: 21px;
  border: none;
  color: #08070a;
  font-family: Supreme-ExtraboldItalic;
  height: 60px;
  width: 234px;
}

.bigcontent {
  color: #ffffff;
  font-size: 98px;
  letter-spacing: 0px;
  line-height: 112px;
  font-family: "Russo One", Regular;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bigcontent {
    font-size: 60px;
    line-height: 74px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bigcontent {
    font-size: 50px;
    line-height: 65px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 991px) {
  .bigcontent {
    font-size: 40px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 500px) {
  .bigcontent {
    font-size: 32px;
    line-height: 44px;
  }
}

.normalcontent {
  font-size: 21px;
  letter-spacing: 0px;
  line-height: 36px;
  color: #ffffff;
  font-family: Supreme-Light;
}

@media only screen and (max-width: 500px) {
  .normalcontent {
    font-size: 18px;
    line-height: 28px;
  }
}

.hrline {
  border: 2px solid #ecfb35;
  opacity: 1;
}

.normalboldcontent {
  font-size: 32px;
  letter-spacing: 0px;
  line-height: 42px;
  color: #ffffff;
  font-family: Supreme-MediumItalic;
}

@media only screen and (max-width: 500px) {
  .normalboldcontent {
    font-size: 21px;
    line-height: 32px;
  }
}

.chartitles {
  font-size: 18px;
  letter-spacing: 3.6px;
  line-height: 36px;
  color: #ffffff;
  font-family: Supreme-ExtraboldItalic;
}

@media only screen and (max-width: 500px) {
  .chartitles {
    font-size: 14px;
    letter-spacing: 2.8px;
  }
}

.charname {
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 36px;
  color: #ffffff;
  font-family: Supreme-ExtraboldItalic;
}

.amountdiscount {
  text-decoration: line-through;
}

.mrpsize {
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 36px;
  color: #ffffff;
  font-family: Supreme-Bold;
}

.gstsize {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 36px;
  color: #ffffff;
  font-family: Supreme-Bold;
}

@media only screen and (max-width: 500px) {
  .charname {
    font-size: 14px;
  }
}

.imagescroll {
  background: url(../../assets/webpimages/simple\ bnr.webp);
  background-position: center;
  background-size: cover;
  /* overflow-y:scroll; */
  /* height: 200px; */
}

@media only screen and (min-width: 768px) {
  .imagewidth {
    width: 100%;
  }
}

.imagerel {
  position: relative;
}

.imageabs {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scrollcontent {
  height: 300px;
  /* width: 450px; */
  overflow: scroll;
  overflow-x: hidden;
}

@media only screen and (min-width: 1400px) {
  .scrollcontent {
    height: 400px;
  }
}

@media only screen and (min-width: 2000px) {
  .scrollcontent {
    height: 650px;
  }
}

.v2head {
  color: #ffffff;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0px;
  font-family: Supreme-ExtraboldItalic;
}

@media only screen and (max-width: 500px) {
  .v2head {
    font-size: 18px;
    line-height: 24px;
  }
}

.comingsoonbtn {
  border-radius: 30px;
  opacity: 1;
  font-size: 21px;
  border: 1px solid #ecfb35;
  color: #ecfb35;
  font-family: Supreme-ExtraboldItalic;
  background: #242327;
}

.scrollbottom {
  position: fixed;
  bottom: 25px;
  right: 10px;
}

.authdesc {
  color: #fff;
  font-family: Supreme-Bold;
  font-size: 21px;
  line-height: 36px;
  letter-spacing: 0px;
}

@media only screen and (max-width: 500px) {
  .authdesc {
    font-size: 18px;
    line-height: 24px;
  }

  .cont {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .mrpsize {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

@media (max-width:767px) {
  .mt5 {
    margin-top: 8rem !important;
  }
}

.bg {
  background-color: #242327;
}

.cont {
  font-family: Supreme-Bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0;
  color: #FFFFFF;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {

  #col2,
  #col3 {
    padding-top: 8rem !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

  #col2,
  #col3,
  #col1 {
    padding-top: 7rem !important;
  }
}